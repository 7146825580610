import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  List,
  ListItem,
  Select,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import GoogleMapReact from 'google-map-react';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { ChangeEvent, useEffect, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import {
  FIND_CLASS_CLASSES_FOUND,
  FIND_CLASS_NO_CLASSES_FOUND,
  FIND_CLASS_PLACE_SELECTED,
  FIND_CLASS_SEARCH_STARTED,
} from '../../constants/events';
import { useTypedSelector } from '../../hooks/store';
import {
  useGetEventsByBossHandleQuery,
  useGetEventsByLocationQuery,
  useGetUserByHandleQuery,
} from '../../store/api';
import { resultListItemStyle, resultListStyle } from '../../styles/global';
import { ApiErrorResponse } from '../../types/ApiErrors';
import { EventResponse } from '../../types/events';
import { Place, PlaceEventData } from '../../types/places';
import { UserPublic } from '../../types/users';
import analyticsEvent from '../../utils/logEvent';
import { getPlaceLat, getPlaceLng } from '../../utils/places';
import { serializePlaceForEvents } from '../../utils/serialize/place';
import { capitalizeFirstLetter } from '../../utils/string';
import BecomeABossCTA from '../ctas/BecomeABossCTA';
import MapMarker from '../ui/MapMarker';
import EventsList from './EventsList';

const findEventsContainerStyle = {
  display: { base: 'block', lg: 'flex' },
  flexDirection: 'row',
  height: { lg: '50vh' },
  minHeight: { lg: '42rem' },
  background: '#f8f8f8',
  color: 'black',
} as const;

const findEventsStyle = {
  flex: 1,
  width: { base: '100%', lg: '40vw' },
  margin: 0,
} as const;

const mapContainerStyle = {
  flex: 1,
  maxWidth: { base: '100vw', lg: '60vw' },
  height: { base: '25vh', lg: '100%' },
  padding: 0,
  margin: 0,
  color: 'black',

  '> div': {
    width: { base: '100vw !important', lg: '60vw !important' },
  },
} as const;

const defaultMapProps = {
  center: {
    lat: 51.45662,
    lng: -0.13976,
  },
  zoom: 12,
};

export default function FindEvents() {
  const t = useTranslations('QueenHub');
  const tGlobal = useTranslations('Global');
  const { user } = useTypedSelector((state) => state);

  const [boss, setBoss] = useState<UserPublic | null>(null);
  const [userHomeLocation, setUserHomeLocation] = useState<Place | null>(null);
  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);
  const [selectedPlaceEventData, setSelectedPlaceEventData] = useState<PlaceEventData | null>(null);
  const [placeInputValue, setPlaceInputValue] = useState<string>('');
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number } | null>(null);
  const [mapZoom, setMapZoom] = useState<number | null>(null);
  const [radius, setRadius] = useState<number>(10);

  const router = useRouter();

  const [events, setEvents] = useState<EventResponse[]>([]);
  const [bossHandle, setBossHandle] = useState<string>('');

  useEffect(() => {
    setBossHandle(router.query.boss_handle as string);

    if (!router.query.boss_handle) {
      setEvents([]);
    }
  }, [router]);

  // Call to get classes from db only when a place has been selected or user's home location exists
  const { data, isFetching, isLoading } = useGetEventsByLocationQuery(
    bossHandle
      ? skipToken
      : selectedPlace
      ? {
          lat: getPlaceLat(selectedPlace),
          lng: getPlaceLng(selectedPlace),
          radius: radius,
        }
      : userHomeLocation
      ? {
          lat: getPlaceLat(userHomeLocation),
          lng: getPlaceLng(userHomeLocation),
          radius: radius,
        }
      : skipToken,
  );

  const {
    data: eventData,
    isFetching: eventIsFetching,
    isLoading: eventIsLoading,
  } = useGetEventsByBossHandleQuery(bossHandle ? { bossHandle: bossHandle } : skipToken);

  const {
    data: bossData,
    isFetching: bossIsFetching,
    isLoading: bossIsLoading,
  } = useGetUserByHandleQuery(bossHandle ? { instagramHandle: bossHandle } : skipToken);

  // Init autocomplete places service
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      debounce: 300, // sets how often the places api is called for predictions (in ms). Increase to help quotas
    });

  const eventsLoaded = data && !isPlacePredictionsLoading && !isLoading && !isFetching;

  const bossEventsLoaded = eventData && !eventIsLoading && !eventIsFetching;

  useEffect(() => {
    if (bossEventsLoaded) {
      if (!(eventData instanceof ApiErrorResponse) && eventData.length > 0) {
        setEvents(eventData);

        setMapCenter({
          lat: eventData[0].location.geometry.location.lat,
          lng: eventData[0].location.geometry.location.lng,
        });
        setMapZoom(12);
      }
    }
  }, [bossEventsLoaded, eventData]);

  useEffect(() => {
    // If user's home location is set, search classes for this place on initial load
    if (user.homeLocation && !bossHandle) {
      setUserHomeLocation(user.homeLocation);
      setPlaceInputValue(user.homeLocation.formatted_address || '');
    }
  }, [user.homeLocation, bossHandle]);

  const bossDataLoaded = bossData && !bossIsLoading && !bossIsFetching;

  useEffect(() => {
    if (bossDataLoaded && !(bossData instanceof ApiErrorResponse)) {
      setBoss(bossData);
    }
  }, [bossData, bossDataLoaded]);

  useEffect(() => {
    // Listen for Classes query data updated
    if (data && data instanceof Array) {
      if (data[0]) {
        setEvents(data);
        console.log(events);

        // Classes endpoint returned 1+ classes
        analyticsEvent(FIND_CLASS_CLASSES_FOUND, {
          total_classes_found: data.length,
          ...selectedPlaceEventData,
        });
      } else {
        // Classes endpoint returned 0 classes
        analyticsEvent(FIND_CLASS_NO_CLASSES_FOUND, {
          ...selectedPlaceEventData,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function onInputChange(event: ChangeEvent<HTMLInputElement>): void {
    if (placeInputValue === '') {
      analyticsEvent(FIND_CLASS_SEARCH_STARTED);
    }
    if (selectedPlace) {
      setSelectedPlace(null);
    }
    getPlacePredictions({ input: event.target.value });
    setPlaceInputValue(event.target.value);
  }

  function placeSelected(place: any): void {
    setEvents([]);
    setPlaceInputValue(place.description);
    placesService?.getDetails(place, (details) => {
      if (!details) return;

      const serializedPlaceEventData = serializePlaceForEvents(place, details);
      analyticsEvent(FIND_CLASS_PLACE_SELECTED, {
        ...serializedPlaceEventData,
      });
      setSelectedPlace(details);
      setSelectedPlaceEventData(serializedPlaceEventData);

      if (details.geometry?.location) {
        setMapCenter({
          lat: details.geometry.location.lat(),
          lng: details.geometry.location.lng(),
        });
        setMapZoom(12);
      }
    });
    getPlacePredictions({ input: '' });
  }

  function onClickfindEvents() {
    const { pathname, query } = router;
    delete router.query.boss_handle;
    router.replace({ pathname, query }, undefined, { shallow: true });
  }

  {
    /* Google maps api script must be loaded in parent page component before this component renders - see queen-hub.tsx for an example.
      Including apiKey arg in packages init functions would also trigger the script loading, but with two google packages used there's a duplicate script loaded.
      TODO: improve duplicate scripts loading due to two google packages being used, and requirement to load script on parent page */
  }

  return (
    <Box sx={findEventsContainerStyle} overflow="hidden">
      <Container sx={mapContainerStyle}>
        <GoogleMapReact
          defaultCenter={defaultMapProps.center}
          defaultZoom={defaultMapProps.zoom}
          center={mapCenter || defaultMapProps.center}
          zoom={mapZoom || defaultMapProps.zoom}
        >
          {events.map((e, index) => (
            <MapMarker
              key={`map_marker_${index}`}
              lat={e.location.geometry.location.lat}
              lng={e.location.geometry.location.lng}
            />
          ))}
        </GoogleMapReact>
      </Container>

      <Container
        size="md"
        sx={{ ...findEventsStyle }}
        paddingTop={{ base: '0.75rem', lg: '3.5rem !important' }}
        paddingX={{ base: '0.75rem', lg: '3.5rem !important' }}
        className="find-class-container"
      >
        {bossHandle && !bossEventsLoaded ? (
          <Flex justifyContent="center">
            <Spinner
              thickness="6px"
              speed="0.65s"
              emptyColor="gray.100"
              color="pink.500"
              h="80px"
              w="80px"
            />
          </Flex>
        ) : bossHandle && events.length > 0 ? (
          <Heading ml="0.625rem">{`SOS ${capitalizeFirstLetter(events[0].boss.firstName)}'s ${t(
            'findEvents.bossEvents',
          )}`}</Heading>
        ) : bossHandle && events.length === 0 ? (
          !boss || !boss?.bossQualifications?.commercial ? (
            <>
              <Heading>{`${bossHandle} ${t('findEvents.bossNotFound')}`}</Heading>
              <Text>{t('findEvents.bossNotFoundExtraText')}</Text>
              <Text>{t('findEvents.noEventsText')}</Text>
              <Button onClick={onClickfindEvents}>{t('findEvents.findEventsButton')}</Button>
            </>
          ) : (
            <>
              <Heading>{`SOS ${boss?.firstName}'s ${t('findEvents.bossEvents')}`}</Heading>
              <Text>{`${t('findEvents.bossHasNoEvents')}`}</Text>
              <Text>{t('findEvents.noEventsText')}</Text>
              <Button onClick={onClickfindEvents}>{t('findEvents.findEventsButton')}</Button>
            </>
          )
        ) : (
          <>
            <Heading>{t('findEvents.heading')}</Heading>
            <Text>{t('findEvents.description')}</Text>
          </>
        )}

        {/* Place search input - changes trigger auto predictions to load */}
        {!bossHandle && (
          <InputGroup size="lg">
            <InputLeftElement pointerEvents="none">
              {isLoading || isFetching ? (
                <Spinner size="sm" />
              ) : (
                <FontAwesomeIcon icon={faSearch} />
              )}
            </InputLeftElement>
            <Input
              type="text"
              fontSize={{ base: 'sm', md: 'md' }}
              placeholder={t('findEvents.inputPlaceholder')}
              value={placeInputValue}
              onChange={(event) => onInputChange(event)}
            />
            <InputRightAddon paddingRight={-1}>
              <Select
                variant="unstyled"
                onChange={(event) => setRadius(Number(event.target.value))}
              >
                <option value={10}>{`+ 10 ${tGlobal('metrics.miles')}`}</option>
                <option value={15}>{`+ 15 ${tGlobal('metrics.miles')}`}</option>
                <option value={20}>{`+ 20 ${tGlobal('metrics.miles')}`}</option>
                <option value={25}>{`+ 25 ${tGlobal('metrics.miles')}`}</option>
                <option value={30}>{`+ 30 ${tGlobal('metrics.miles')}`}</option>
              </Select>
            </InputRightAddon>
          </InputGroup>
        )}

        {/* List of loaded places from auto prediction on users input */}
        {!isPlacePredictionsLoading && placePredictions.length > 0 && (
          <List sx={{ ...resultListStyle, background: 'gray.100' }}>
            {placePredictions.map((place: any, index) => (
              <ListItem
                sx={resultListItemStyle}
                key={`place_${index}`}
                onClick={() => placeSelected(place)}
              >
                {place.description}
              </ListItem>
            ))}
          </List>
        )}
        {!bossHandle && eventsLoaded && events[0] && (
          <EventsList
            events={events}
            setMapCenter={setMapCenter}
            setMapZoom={setMapZoom}
            bossHandle={false}
          />
        )}
        {bossHandle && bossEventsLoaded && events[0] && (
          <EventsList
            events={events}
            setMapCenter={setMapCenter}
            setMapZoom={setMapZoom}
            bossHandle={true}
          />
        )}
        {eventsLoaded && !events[0] && !bossHandle && (
          <>
            <Text mt={4} mb={12}>
              {t('findEvents.noEvents')}
            </Text>
            <BecomeABossCTA />
          </>
        )}
      </Container>
    </Box>
  );
}
